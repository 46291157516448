import React from 'react'
import { Link } from 'gatsby'
import './van-brochure.scss'

const VanBrochure = props => (
  <div className="van-brochure">
    <a target="_blank" download href="/pdf/DARC_VAN_price_list.pdf">
      <button className="brochure-btn">Download</button>
    </a>
  </div>
)

export default VanBrochure
